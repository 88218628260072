/* Vendor imports */
import React, { Component } from 'react'
import { Link } from 'gatsby'
import {
  FaBars,
  FaTimes,
  // FaGithub,
  // FaLinkedin,
  // FaTwitter,
} from 'react-icons/fa'
/* App imports */
import style from './header.module.less'
import Config from '../../../../config'
import Utils from '../../../utils'
import DarkModeToggle from '../../mode/DarkModeToggle'

class Header extends Component {
  constructor() {
    super()
    this.state = {
      lastScrollY: 0,
      fixedHeader: false,
      collapsedMenu: true,
    }
    this.toggleFixedHeader = this.toggleFixedHeader.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.toggleFixedHeader)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.toggleFixedHeader)
  }

  toggleFixedHeader() {
    if (!this.toggleFixedHeader.animationInProgress) {
      this.toggleFixedHeader.animationInProgress = true
      setTimeout(() => {
        this.setState(
          {
            lastScrollY: window.scrollY,
            fixedHeader:
              window.scrollY > 100 && this.state.lastScrollY < window.scrollY,
          },
          () => (this.toggleFixedHeader.animationInProgress = false)
        )
      }, 200)
    }
  }

  toggleMenu() {
    this.setState({
      collapsedMenu: !this.state.collapsedMenu,
    });
  }

  render = () => (
    <div
      className={[style.container, 'main-header'].join( ' ')}
      style={this.state.fixedHeader ? { backgroundImage: 'none' } : null}
    >
      <div className={style.titleContainer}>
        <div className={[style.title, 'header-title'].join(' ')}>
          <Link to={Utils.resolvePageUrl(Config.pages.home)}>
            <h4>{Config.siteTitle}</h4>
            <p
              className={
                this.state.fixedHeader
                  ? style.hiddenDescription
                  : style.visibleDescription
              }
            >
            </p>
          </Link>
        </div>
        <div className={style.menuButton}>
          {this.state.collapsedMenu ? (
            <FaBars size="30" onClick={this.toggleMenu} />
          ) : (
            <FaTimes size="30" onClick={this.toggleMenu} />
          )}
        </div>
      </div>
      <div
        className={[
          style.list,
          this.state.collapsedMenu ? style.collapsedMenu : style.expandedMenu,
        ].join(' ')}
      >
        <ul className="header-items">
          <li>
            <Link to={Utils.resolvePageUrl(Config.pages.home)}>Blog</Link>
          </li>
          <li>
            <Link to={Utils.resolvePageUrl(Config.pages.tag)}>Tags</Link>
          </li>
          <li>
            <Link to={Utils.resolvePageUrl(Config.pages.portfolio)}>
              About
            </Link>
          </li>
        </ul>
        {/* <ul>
          <li>
            <a
              target="_blank"
              rel="nofollow noopener noreferrer"
              href={Config.social.github}
              aria-label="Link to my GitHub repository"
            >
              <FaGithub size="30" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="nofollow noopener noreferrer"
              href="https://dev.to/nedsoft"
              aria-label="Link to my Dev.to page"
            >
              <img
                src="https://d2fltix0v2e0sb.cloudfront.net/dev-badge.svg"
                alt="Orie Chinedu's DEV Profile"
                height="30"
                width="30"
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="nofollow noopener noreferrer"
              href={Config.social.linkedin}
              aria-label="Link to my Linkedin profile"
            >
              <FaLinkedin size="30" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="nofollow noopener noreferrer"
              href={Config.social.twitter}
              aria-label="Link to my twitter account"
            >
              <FaTwitter size="30" />
            </a>
          </li>
        </ul> */}
      </div>
      <DarkModeToggle />
    </div>
  )
}

export default Header
